.Row {
  display: table;
  padding-top: 0.5rem;
  width: 100%;
  table-layout: auto; /*Optional*/
  border-spacing: 10px; /*Optional*/
  overflow: clip;
  /* max-height: 200px; */
}
.Column {
  display: table-cell;
}

.box {
  width: 250px;
  max-height: 200px;
}

.input {
  /* font: inherit; */
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid rgb(63, 133, 240);
  width: 300px;
  max-width: 100%;
  height: 40px;
}

.formItem_extendedBox {
  padding: 10px;
  width: 50vh;
  height: 100px;
  overflow: hidden;
}

.address {
  padding: 10px;
  width: 50vh;
  height: 100px;
}

.formItem_list {
  max-height: 100px;
  /* min-width: 200px; */
  width: 100%;
  overflow: auto;
}

.highlight-red {
    border-color: #ca0808 !important;
    box-shadow: 0 0 10px #ed9e9e;
}
