.root {
  min-width: 500;
  max-width: 100%;
  height: 75vh;
}

.list {
  height: 65vh;
  width: 100%;
  overflow: auto;
  scrollbar-color: rgb(63, 133, 240);
  position: relative;
}

.same-row {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
}

.stat-block {
  padding: 10px;
  text-align: center;
}

.main-stat {
  color: rgb(63, 133, 240);
  font-size: 40px;
  /* border-radius: 12px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  border: 2px solid;
  border-color: rgb(63, 133, 240); */
}

.name-stat {
  font-size: 20px;
}

.flexbox {
  float: right;
  right: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

/* .date-picker {
    position: relative;
    margin-right: 25px;
} */

.excel-export {
  padding-top: 15px;
  position: relative;
}

.excel-export > button {
  border-radius: 10px;
  background-color: white;
  border-color: rgb(63, 133, 240);
}

.search {
  position: relative;
  border-radius: 10px;
  background-color: gainsboro;
  /* margin-right: 10px;
  top: 15px; 
  width: 100%;*/
  min-width: 150px;
  max-height: 35px;
}

.searchIcon {
  padding: 3px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  color: rgb(63, 133, 240);
}

.myReports_dash {
  justify-content: right;
  padding-top: 15px;
  align-items: center;
}

.inputRoot {
  color: white;
  padding-left: 30px;
}

.inputInput {
  padding: 10px;
  /* vertical padding + font size from searchIcon */
  padding-left: 30px;
  /* transition: theme.transitions.create("width"); */
  width: 100%;
  /* [theme.breakpoints.up("md")]: {
      width: "20ch",
    }, */
  color: grey;
}

.dash-checkBox {
  /* margin-top: 10px; */
  margin-right: 5px;
  height: 5vh;
  width: 5vh;
  max-height: 25px;
  max-width: 25px;
  position: relative;
}

.dash-report_list_header {
  display: flex;
  align-items: center;
  /* padding: 15px; */
  /* width: 50rem;
  max-width: 95%; */
}

.dash-report_list_header_item {
  width: 25%;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  font-size: large;
  /* color: rgb(63, 133, 240); */
}

.list::-webkit-scrollbar {
  width: 0 !important;
}
