.report-card {
  display: flex;
  align-items: center;
  padding: 15px;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  border-left: 5px solid;
  border-color: lightgreen;
}

.report-card__1month {
  border-color: yellow;
}

.report-card__1week {
  border-color: orange;
}

.report-card__overDue {
  border-color: red;
}

.report-card-completed {
  border-color: grey;
}

.report-card__id {
  display: flex;
  width: 100px;
  height: 50px;
  border: 1px solid #ececec;
  background-color: #2a2a2a;
  color: white;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
}

.report-card__item {
  width: 25%;
  overflow: hidden;
  text-align: center;
}

/* .report-card__dueDate {
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  color: red;
  border-radius: 12px;
} */
