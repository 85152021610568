.nav-root {
  display: flex;
}

.nav-grow {
  flex-grow: 1;
}

.nav-search {
  position: relative;
  margin-left: 0;
  width: 100%;
}

.nav-search-icon {
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.nav-input-root {
  color: white;
}

.mav-input-input {
  width: 100%;
  color: white;
}

.nav-appBar {
  /* zIndex: theme.zIndex.drawer + 1; */
  height: 65px;
}

.nav-drawer {
  width: 225px;
  flex-shrink: 0;
  /* margin-top: 200px; */
}

.drawerPaper {
  width: 225px;
  height: 100px;
}

.nav-drawerContainer {
  overflow: auto;
  padding-bottom: 20px;
}

.nav-content {
  width: 100%;
  margin-left: 25px;
  margin-top: 85px;
  margin-right: 2vh;
}
