.report_box {
  width: 250px;
  max-height: 200px;
}

.address {
  padding: 10px;
  width: 50vh;
  height: 100px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.button {
  border-radius: 10px;
  background-color: white;
  border-color: rgb(63, 133, 240);
}
